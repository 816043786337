<script setup lang="ts">
defineProps({
  showMenu: {
    type: Boolean,
    default: true,
  },
})
const openMenu = ref(false)

const toggleMenu = () => {
  openMenu.value = !openMenu.value
}
</script>

<template>
  <header
    class="flex justify-between p-5 border-b border-b-gray-500 relative"
    :class="{
      'bg-white': openMenu,
    }"
  >
    <h1 class="font-bold text-xl">
      <NuxtLink to="/top">
        hoteresi
      </NuxtLink>
    </h1>
    <button
      v-if="showMenu"
      :class="{ active: openMenu }"
      class="w-8 h-8 flex flex-col items-center justify-center border-rose-500 border rounded-full"
      @click="toggleMenu"
    >
      <div class="w-4 h-px bg-rose-500 mb-0.5" />
      <div class="w-4 h-px bg-rose-500 mt-0.5" />
    </button>
    <Transition name="fade">
      <HeaderNav
        v-show="openMenu"
        @click="toggleMenu"
      />
    </Transition>
  </header>
</template>

<style lang="scss" scoped>
button {
  div {
    transition: all 0.2s ease;
  }
  &.active {
    div:nth-child(1) {
      transform: rotate(28.3deg) translate(1px, 2px);

    }
    div:nth-child(2) {
      transform: rotate(-28.3deg) translate(2px, -2px);
    }
  }
}
header,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
