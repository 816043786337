<template>
  <div
    class="absolute bg-white w-full py-14 left-0 top-16 z-50"
  >
    <ul
      class="main"
    >
      <li>
        <nuxt-link
          to="/top"
          class="py-4 px-5 w-full block"
        >
          <FaIcon
            icon="door-open"
            class="text-rose-500 mr-1"
          />
          部屋を貸し出す
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
          to="/mypage"
          class="py-4 px-5 w-full block"
        >
          <FaIcon
            icon="user"
            class="text-rose-500 mr-1"
          />
          マイページ
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
          to="/faq"
          class="py-4 px-5 w-full block"
        >
          <FaIcon
            icon="question-circle"
            class="text-rose-500 mr-1"
          />
          FAQ
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
          to="/contact"
          class="py-4 px-5 w-full block"
        >
          <FaIcon
            icon="paper-plane"
            class="text-rose-500 mr-1"
          />
          お問い合わせ
        </nuxt-link>
      </li>
    </ul>
    <!-- /.main -->
    <ul class="sub">
      <li>
        <a
          href="https://www.ymthr.com/"
          target="_blank"
          class="py-4 px-5 w-full block font-bold text-xs"
        >
          <FaIcon
            icon="angle-right"
            class="text-rose-500 mr-1"
          />
          会社概要
        </a>
      </li>
      <li>
        <nuxt-link
          class="py-4 px-5 w-full block font-bold text-xs"
        >
          <FaIcon
            icon="angle-right"
            class="text-rose-500 mr-1"
          />
          個人情報保護方針
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
          class="py-4 px-5 w-full block font-bold text-xs"
        >
          <FaIcon
            icon="angle-right"
            class="text-rose-500 mr-1"
          />
          利用規約
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
          class="py-4 px-5 w-full block font-bold text-xs"
        >
          <FaIcon
            icon="angle-right"
            class="text-rose-500 mr-1"
          />
          プライバシーポリシー
        </nuxt-link>
      </li>
    </ul>
    <!-- /.sub -->
  </div>
</template>
