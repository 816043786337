<template>
  <footer class="py-5">
    <ul class="px-14 flex gap-5 items-center justify-center">
      <li>
        <a
          href="https://www.ymthr.com/"
          class="underline text-xs"
        >
          会社概要
        </a>
      </li>
      <li>
        <NuxtLink
          to="#"
          class="underline text-xs"
        >
          利用規約
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="#"
          class="underline text-xs"
        >
          プライバシーポリシー
        </NuxtLink>
      </li>
    </ul>
    <div class="flex items-center py-14">
      <img
        src="/public/img/logo_karakami.png"
        alt="karakami"
        width="142"
        height="65"
        class="mx-auto"
      >
    </div>
    <p class="text-center text-xs font-roboto text-slate-500">
      &copy; Karakami HOTELS &amp; RESORTS ALL RIGHTS RESERVED.
    </p>
  </footer>
</template>
